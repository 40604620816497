@tailwind base;
@tailwind components;
@tailwind utilities;
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.close {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: inline-block;
  float: right;
  width: 22px;
  height: 22px;
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 2px black;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: background 0.5s;
}

.close:after,
.close:before {
  position: absolute;
  content: '';
  background-color: black;
}

.close:after {
  width: 2px;
  height: 10px;
  top: 6px;
  left: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close:before {
  width: 10px;
  height: 2px;
  top: 10px;
  left: 6px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close:hover {
  background: black;
  box-shadow: none;
}

.close:hover:after,
.close:hover:before {
  background: white;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-container {
  overflow-x: auto !important; /* Force overflow if needed */
  max-width: 100%; /* Ensure it takes up available space */
}
