.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  align-items: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 18px;
  min-width: 32px;
}

.pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-item.selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.pagination-item .arrow::before {
  content: '';
  position: relative;
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.pagination-item .arrow.right {
  transform: rotate(45deg);
}

.pagination-item.disabled {
  pointer-events: none;
}

.pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.required-field::after {
  content: '*';
  color: red;
}
.rules {
  margin-top: 8px;
}

.rule-passed {
  color: #52c41a; /* Green color for passed rules */
}

.rule-failed {
  color: #ff4d4f; /* Red color for failed rules */
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #f8f8f8;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
